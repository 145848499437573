<template>
	<div style="background: #fff;padding: 15px;">
		<div class="filter-container">
			<!-- 代理等级 -->
			<div class="filter-item">
				<label class="label">关键字:</label>
				<el-input
					v-model="searchKey"
					@keyup.enter.native="goodsSearch"
					placeholder="分组名称"
					style="width: 200px;;margin-right: 10px;"
					clearable
				></el-input>
			</div>
			<div class="filter-item">
				<buttonPermissions :datas="'searchGroup'">
					<el-button type="primary" style="margin-left:25px;width:90px;" @click="goodsSearch">查询</el-button>
				</buttonPermissions>
				<el-button type="primary" style="margin-left:25px;width:90px;" @click="eidtGroup(null)">添加</el-button>
				<buttonPermissions :datas="'exportGroup'">
					<el-button style="margin-left:25px;width:90px;" @click="groupExport">导出</el-button>
				</buttonPermissions>
			</div>
		</div>

		<!-- 表格 -->
		<div class="table-container">
			<el-table :data="groupData" v-loading="loading">
                <el-table-column prop="groupId" label="分组ID" width="80"></el-table-column>
				<el-table-column prop="groupName" label="分组名称"></el-table-column>
				<el-table-column label="分组商品">
					<template slot-scope="scope">
						<div v-if="scope.row.goodsCount==0">{{scope.row.goodsCount}}</div>
						<div
							v-else
							style="color: #409EFF;cursor:pointer"
							@click="jumpToShop(scope.row)"
						>{{scope.row.goodsCount}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="createTime" label="创建时间"></el-table-column>
				<el-table-column label="操作" width="140">
					<template slot-scope="scope">
						<div style="display: flex;align-items: center;">
							<el-button type="text" @click="eidtGroup(scope.row)">编辑</el-button>
							<buttonPermissions :datas="'deleteGroup'">
								<span
									style="color:#f00;margin-left:10px;cursor: pointer;"
									@click="deleteChose(scope.row)"
								>删除</span>
							</buttonPermissions>
						</div>
					</template>
				</el-table-column>
			</el-table>
			<div style="display:flex;justify-content:space-between;align-items:center;">
				<div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin-left: 13px;"></div>
				<el-pagination
					v-if="Total"
					style="margin-top:20px;float:right;"
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="currentPage"
					:page-sizes="[10, 20, 30, 40, 50]"
					:page-size="pageSize"
					layout="total, sizes, prev, pager, next, jumper"
					:total="Total"
				></el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
import {
	productGroupListData,
	productGroupSave,
	productGroupDelete,
	goodsGroupExport
} from '@/api/goods'
import config from '@/config/index'
import buttonPermissions from '@/components/buttonPermissions';
import utils from '@/utils/util.js';
export default {
	components: {
		buttonPermissions
	},
	data () {
		var checkName = (rule, value, callback) => {
			if (!value) {
				return callback(new Error('请输入分组名称'));
			} else if (!value.trim()) {
				return callback(new Error('请输入分组名称'));
			} else {
				return callback()
			}
		};
		return {
			ruleForm: {
				name: ''
			},
			rules: {
				name: [{
					required: true,
					// message: '请输入分组名称',
					trigger: 'blur',
					validator: checkName
				},
				{
					min: 1,
					max: 16,
					message: '最多输入16个字',
					trigger: 'blur'
				}
				]
			},
			groupData: [],
			searchKey: '',
			loading: false,
			editVisable: false,
			groupName: "",
			currentPage: 1,
			pageSize: 20,
			Total: 1,
			eidtGroupData: {},
			exportUrl: config.EXPORT_URL,

		};
	},
	beforeMount () {
		this.getList()
	},
	methods: {
		// 导出
		async groupExport () {
			let data = {
				name: this.searchKey, // 搜索关键字
				pageNo: this.currentPage, // 略过数据
				pageSize: this.pageSize, // 取的数据
				isDownload: true,    //下载文件标记
			}
			let res = await goodsGroupExport(data);
			utils.convertRes2Blob(res);
		},
		jumpToShop (row) {
			this.$router.push({
				path: '/goods/goodsGroupManager',
				query: {
					groupId: row.groupId,
					isEdit: 2,//1 为编辑  2 为点击数量进入
				}
			})
		},
		// 获取列表数据
		async getList () {
			try {
				this.loading = true
				let data = {
					name: this.searchKey, // 搜索关键字
					pageNo: this.currentPage, // 略过数据
					pageSize: this.pageSize, // 取的数据
				}
				let result = await productGroupListData(data);
				this.Total = result.data.total;
				this.groupData = result.data.records || [];

			} catch (e) {
				console.log(e)
			} finally {
				this.loading = false
			}

		},
		goodsSearch () {
			this.currentPage = 1;
			this.getList();
		},
		eidtGroup (row) {
			//系统分组
			// if(row.isSystem){
			// 	this.$message({
			// 		showClose: true,
			// 		type: 'warning',
			// 		message: '系统分组不可编辑!'
			// 	});
			// 	return;
			// }
			var groupId = 0;
			var name = "";
			var isSystem = 0;
			if (row) {
				groupId = row.groupId;
				name = row.groupName;
				isSystem = row.isSystem ? 1 : 0;
			}
			this.$router.push({ path: '/goods/goodsGroupManager', query: { groupId: groupId, name: name, isSystem: isSystem } })
		},
		deleteChose (record) {
			//系统分组
			if (record.isSystem) {
				this.$message({
					showClose: true,
					type: 'warning',
					message: '系统分组不可删除!'
				});
				return;
			}
			this.$confirm('删除后不可恢复，是否确认确认继续删除?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(async () => {
				let ids = [record.groupId];
				let result = await productGroupDelete(ids);
				if (result.success) {
					this.$message({
						showClose: true,
						type: 'success',
						message: '删除成功!'
					});
					this.goodsSearch();
				} else {
					this.$message({
						showClose: true,
						type: 'error',
						message: result.alertMsg
					});
				}
			}).catch(() => {
				this.$message({
					showClose: true,
					type: 'info',
					message: '已取消删除'
				});
			})
		},
		async saveChange (formName) {
			this.$refs[formName].validate(async (valid) => {
				if (valid) {
					try {
						this.loading = true
						let data = {
							Id: this.eidtGroupData.Id ? this.eidtGroupData.Id : 0,
							GroupName: this.ruleForm.name
						}
						let result = await productGroupSave(data)
						if (result.IsSuccess) {
							this.$message({
								showClose: true,
								type: 'success',
								message: '操作成功!'
							});
							this.editVisable = false;
						}

					} catch (e) {
						console.log(e)
					} finally {
						this.goodsSearch();
						setTimeout(() => {
							this.loading = false
						}, 500)

					}
				} else {
					return false;
				}
			})


		},
		// 切换显示条数
		handleSizeChange (val) {
			// console.log(`每页 ${val} 条`);
			this.pageSize = val;
			this.getList();
		},

		// 翻页
		handleCurrentChange (val) {
			console.log(`当前页: ${val}`);
			this.currentPage = val;
			this.getList();
		},
	}
}
</script>

<style lang="less" scoped>
.exportBtn {
	position: relative;
}

.exportInput {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	width: 90px;
}

#inputsId {
	display: inline-block;
	height: 35px;
	width: 90px;
}
</style>
